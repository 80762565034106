@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
.MainHeading {
  font-size: 400%;
  color: rgb(24, 50, 25);
  font-family: "Comfortaa", cursive;
  text-shadow: 5px rgb(122, 184, 107);
  padding: 5px;
  margin: 5px;
  -webkit-text-decoration: bold;
          text-decoration: bold;
}

.HeaderIcons {
  font-size: 350% !important;
  width: 350% !important;
  color: rgb(24, 50, 25) !important;
  border: 2px solid rgb(122, 184, 107);
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  text-decoration: none;
}
.HeaderIcons:hover, .HeaderIcons:active {
  background-color: rgb(122, 184, 107);
}

.Icons, .ProductItem .imgIcon {
  height: 50px !important;
  width: 50px !important;
  font-size: 30px !important;
  color: rgb(24, 50, 25);
  border: 2px solid rgb(122, 184, 107);
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Icons:hover, .ProductItem .imgIcon:hover {
  background-color: rgb(122, 184, 107);
}

.errorText {
  color: red;
  height: 20px;
  width: 100%;
  text-align: center;
  margin: 10px;
}

.SubmitButton {
  height: 50px;
  width: 150px;
  font-size: 20px;
  color: rgb(24, 50, 25);
  border: 2px solid rgb(122, 184, 107);
  padding: 5px;
  margin: 10px;
}
.SubmitButton:hover {
  background-color: rgb(122, 184, 107);
}

.NavButton {
  height: 50px;
  width: 150px;
  font-size: 20px;
  color: rgb(24, 50, 25);
  border: 2px solid rgb(122, 184, 107);
  padding: 5px;
  margin: 10px;
}
.NavButton:hover {
  background-color: rgb(122, 184, 107);
}

.inputFields {
  height: 5vh;
  width: 70vw;
  border: none;
}
.inputFields:active, .inputFields:focus {
  outline: none;
  border-bottom: 1px solid grey;
}

.HeaderBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid rgb(122, 184, 107);
}

.ProductItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  border-top: 1px solid rgb(122, 184, 107);
  margin: 10px;
  z-index: 0;
}
.ProductItem .drop_zone {
  position: relative;
  height: 150px;
  z-index: 2;
}
.ProductItem input {
  text-align: center;
  padding: 0;
  margin: 0;
}
.ProductItem input[type=number] {
  width: 50px;
}
.ProductItem textarea {
  text-align: center;
  height: 150px;
  border: none;
}