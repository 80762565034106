@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
.MainHeading {
  font-size: 400%;
  color: rgb(24, 50, 25);
  font-family: "Comfortaa", cursive;
  text-shadow: 5px rgb(122, 184, 107);
  padding: 5px;
  margin: 5px;
  -webkit-text-decoration: bold;
          text-decoration: bold;
}

.HeaderIcons {
  font-size: 350% !important;
  width: 350% !important;
  color: rgb(24, 50, 25) !important;
  border: 2px solid rgb(122, 184, 107);
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  text-decoration: none;
}
.HeaderIcons:hover, .HeaderIcons:active {
  background-color: rgb(122, 184, 107);
}

.Icons, .ShopItemCheckout .iconContainer img, .ShopItem .iconContainer .imgIcon {
  height: 50px !important;
  width: 50px !important;
  font-size: 30px !important;
  color: rgb(24, 50, 25);
  border: 2px solid rgb(122, 184, 107);
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Icons:hover, .ShopItemCheckout .iconContainer img:hover, .ShopItem .iconContainer .imgIcon:hover {
  background-color: rgb(122, 184, 107);
}

.errorText {
  color: red;
  height: 20px;
  width: 100%;
  text-align: center;
  margin: 10px;
}

.SubmitButton {
  height: 50px;
  width: 150px;
  font-size: 20px;
  color: rgb(24, 50, 25);
  border: 2px solid rgb(122, 184, 107);
  padding: 5px;
  margin: 10px;
}
.SubmitButton:hover {
  background-color: rgb(122, 184, 107);
}

.NavButton {
  height: 50px;
  width: 150px;
  font-size: 20px;
  color: rgb(24, 50, 25);
  border: 2px solid rgb(122, 184, 107);
  padding: 5px;
  margin: 10px;
}
.NavButton:hover {
  background-color: rgb(122, 184, 107);
}

.inputFields {
  height: 5vh;
  width: 70vw;
  border: none;
}
.inputFields:active, .inputFields:focus {
  outline: none;
  border-bottom: 1px solid grey;
}

.HeaderBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid rgb(122, 184, 107);
}

.ShopItem {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 250px;
  height: 700px;
  padding: 5px;
  margin: 5px;
}
.ShopItem .productItemName {
  height: 50px;
}
.ShopItem .productItemDescription {
  text-align: center;
}
.ShopItem .ProductImage {
  width: 100%;
  height: auto;
}
.ShopItem .iconContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.ShopItemCheckout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  justify-items: center;
  width: 100%;
  padding: 5px;
  margin: 5px;
  border-top: 1px solid rgb(24, 50, 25);
}
.ShopItemCheckout .productItemName {
  width: 150px;
}
.ShopItemCheckout .productItemDescription {
  width: 200px;
  height: 100px;
}
.ShopItemCheckout .ProductImage {
  width: auto;
  height: 50%;
}
.ShopItemCheckout .iconContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}