@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Fredoka+One&display=swap");
.MainHeading {
  font-size: 400%;
  color: rgb(24, 50, 25);
  font-family: "Comfortaa", cursive;
  text-shadow: 5px rgb(122, 184, 107);
  padding: 5px;
  margin: 5px;
  -webkit-text-decoration: bold;
          text-decoration: bold;
}

.HeaderIcons {
  font-size: 350% !important;
  width: 350% !important;
  color: rgb(24, 50, 25) !important;
  border: 2px solid rgb(122, 184, 107);
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  text-decoration: none;
}
.HeaderIcons:hover, .HeaderIcons:active {
  background-color: rgb(122, 184, 107);
}

.Icons {
  height: 50px !important;
  width: 50px !important;
  font-size: 30px !important;
  color: rgb(24, 50, 25);
  border: 2px solid rgb(122, 184, 107);
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Icons:hover {
  background-color: rgb(122, 184, 107);
}

.errorText {
  color: red;
  height: 20px;
  width: 100%;
  text-align: center;
  margin: 10px;
}

.SubmitButton {
  height: 50px;
  width: 150px;
  font-size: 20px;
  color: rgb(24, 50, 25);
  border: 2px solid rgb(122, 184, 107);
  padding: 5px;
  margin: 10px;
}
.SubmitButton:hover {
  background-color: rgb(122, 184, 107);
}

.NavButton, .Checkout form button {
  height: 50px;
  width: 150px;
  font-size: 20px;
  color: rgb(24, 50, 25);
  border: 2px solid rgb(122, 184, 107);
  padding: 5px;
  margin: 10px;
}
.NavButton:hover, .Checkout form button:hover {
  background-color: rgb(122, 184, 107);
}

.inputFields, .Checkout form .deliveryShow input, .Checkout form .address input, .Checkout form .deliveryShow .card_field, .Checkout form .address .card_field {
  height: 5vh;
  width: 70vw;
  border: none;
}
.inputFields:active, .Checkout form .deliveryShow input:active, .Checkout form .address input:active, .Checkout form .deliveryShow .card_field:active, .Checkout form .address .card_field:active, .inputFields:focus, .Checkout form .deliveryShow input:focus, .Checkout form .address input:focus, .Checkout form .deliveryShow .card_field:focus, .Checkout form .address .card_field:focus {
  outline: none;
  border-bottom: 1px solid grey;
}

.HeaderBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid rgb(122, 184, 107);
}

.Checkout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.Checkout .CheckoutBasketContainer {
  display: flex;
  flex-direction: column;
}
.Checkout .CheckoutBasketContainer p {
  width: auto;
  height: 20px;
  text-align: center;
  font-size: 15px;
}
.Checkout form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  margin-bottom: 10px;
}
.Checkout form .deliveryHide {
  display: none;
}
.Checkout form .deliveryShow, .Checkout form .address {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.Checkout form .deliveryShow .CheckBoxContainer, .Checkout form .address .CheckBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  width: 70vw;
}
.Checkout form .deliveryShow .CheckBoxContainer p, .Checkout form .address .CheckBoxContainer p {
  font-size: 15px;
}
.Checkout form .deliveryShow .CheckBoxContainer input, .Checkout form .address .CheckBoxContainer input {
  width: 15px;
}
.Checkout form .deliveryShow .card_field:focus, .Checkout form .address .card_field:focus {
  outline: none;
  border-bottom: 1px solid grey;
}
.Checkout form button {
  width: 70vw !important;
}